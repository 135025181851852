import { db } from "../firebase"

async function getUserHistory(userID) {
  let snapshot = await db
    .collection("users")
    .doc(userID)
    .collection("history")
    .orderBy("date", "desc")
    .get()

  return Promise.resolve(snapshot)
}

export default getUserHistory
