import { db } from "../firebase"

function updateSkillsAndExperiences(userID, skills, experiences) {
  return db
    .collection("users")
    .doc(userID)
    .update({
      skills: skills,
      experiences: experiences,
    })
}

export default updateSkillsAndExperiences
