import { navigate } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import Table from "react-bootstrap/Table"
import Tabs from "react-bootstrap/Tabs"
import Loader from "../../components/utils/Loader"
import profilePic from "../../images/profile.svg"
import getUserData from "../../scripts/getUserData"
import getUserHistory from "../../scripts/getUserHistory"
import updateSkillsAndExperiences from "../../scripts/updateSkillsAndExperiences"
import "../../styles/ProfilePage.css"
import { auth, default as firebase } from "../../firebase"
import logout from "../../scripts/logout"
import AsyncButton from "../../components/utils/AsyncButton"
import VolunteerHoursCertificate from "./VolunteerHoursCertificate"
import Layout from "../utils/layout"
export default class StudentProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      studentName: "",
      highschool: "",
      grade: "",
      firstName: "",
      lastName: "",
      email: "",
      tableMap: [],
      dataMap: [],
      skills: [],
      totalHours: 0,
      experiences: [],
      inputSkills: ["", "", ""],
      inputExp: ["", "", ""],
      buttonText: "Update Skills and Experiences",
      buttonColor: "outline-primary",
      initialized: false,
      userData: {},
    }
    this.updateSE = this.updateSE.bind(this)
    this.init = this.init.bind(this)
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        if (!user.emailVerified) {
          logout().then(() => {
            navigate("")
          })
        } else {
          this.setState({ authenticated: true })
        }
      }
    })
  }

  init() {
    if (this.state.authenticated) {
      this.setState({ initialized: true })
      let user = auth.currentUser
      getUserHistory(user.uid)
        .then(snapshot => {
          let tempMap = []
          let dataMap = []
          let hours = 0
          snapshot.forEach(doc => {
            let data = doc.data()
            hours += data["volunteerHours"]
            dataMap.push(data)
            tempMap.push(
              <tr>
                <td>{data["taskName"]}</td>
                <td>{data["volunteerHours"]}</td>
                <td>{data["teacherName"]}</td>
                <td>
                  {data["date"]
                    .toDate()
                    .toString()
                    .substring(4, 16)}
                </td>
              </tr>
            )
          })
          this.setState({
            tableMap: tempMap,
            totalHours: hours,
            dataMap: dataMap,
          })
          getUserData(user.uid)
            .then(data => {
              this.setState({
                studentName: data["firstName"] + " " + data["lastName"],
                highschool: data["school"],
                skills: data["skills"],
                experiences: data["experiences"],
                grade: data.grade,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                userData: data,
              })
            })
            .catch(error => {
              console.log(error)
              //TODO Error handle
            })
        })
        .catch(error => {
          console.log(error)
          //TODO Error Handle
        })
    }
  }

  navigateTo() {
    navigate("/404/")
  }

  updateSE(reset) {
    let newSkills = this.state.skills
    let newExp = this.state.experiences
    for (var i = 0; i < newSkills.length; i++) {
      if (newSkills[i] === "") {
        newSkills[i] = this.state.skills[i]
      }
      if (newExp[i] === "") {
        newExp[i] = this.state.experiences[i]
      }
    }
    let user = auth.currentUser

    updateSkillsAndExperiences(user.uid, newSkills, newExp)
      .then(() => {
        reset("Skills Updated!")
        this.setState({
          inputSkills: newSkills,
          inputExp: newExp,
        })
      })
      .catch(error => {
        reset("An error occured. Try again?")
      })
  }

  handleChange = event => {
    let name = event.target.name.split("|")
    let type = name[0]
    let index = parseInt(name[1])

    if (type === "skill") {
      let tmp = this.state.skills
      tmp[index] = event.target.value
      this.setState({ skills: tmp })
    } else {
      let tmp = this.state.experiences
      tmp[index] = event.target.value
      this.setState({ experiences: tmp })
    }
  }
  //WORKS, JUST NEED TO SPECIFY AN ID
  // print = () => {
  //   var element = document.getElementById("toPrint")
  //   html2pdf(element)
  // }

  render() {
    if (!this.state.initialized) {
      this.init()
    }

    if (this.state.initialized) {
      return (
        <>
          <Container>
            <div class="codePenCard2 wrapper">
              <div class="codePenCard2 profile-card js-profile-card">
                <div class="codePenCard2 profile-card__img">
                  <div className="selector">
                    <img src={profilePic} className="imageMargin" alt=""></img>
                  </div>
                </div>

                <div class="codePenCard2 profile-card__cnt js-profile-cnt">
                  <div class="codePenCard2 profile-card__name">
                    {this.state.studentName}
                  </div>
                  <div class="codePenCard2 profile-card__txt">
                    Student at <strong>{this.state.highschool}</strong>
                  </div>
                  <div class="codePenCard2 profile-card-loc">
                    <span class="codePenCard2 profile-card-loc__icon">
                      <svg class="codePenCard2 icon">
                        <use xlink="#icon-location"></use>
                      </svg>
                    </span>
                  </div>
                  <Tabs
                    defaultActiveKey="Task History"
                    transition={false}
                    id="noanim-tab-example"
                  >
                    <Tab eventKey="Task History" title="Service Hours Log">
                      <Row
                        style={{
                          marginTop: "60px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <Table
                          striped
                          bordered
                          // responsive
                          hover
                          style={{ marginLeft: "50px", marginRight: "50px" }}
                        >
                          <thead>
                            <tr>
                              <th>Project Name</th>
                              <th>Number of Volunteer Hours</th>
                              <th>Teacher Name</th>
                              <th>Date Completed</th>
                            </tr>
                          </thead>
                          <tbody>{this.state.tableMap}</tbody>
                        </Table>
                      </Row>{" "}
                      <div style={{ marginTop: "20px" }}>
                        <VolunteerHoursCertificate
                          hoursLog={this.state.dataMap}
                          totalHours={this.state.totalHours}
                          userData={this.state.userData}
                        />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Skills and Experiences"
                      title="Skills and Experiences"
                    >
                      <Form className="formAlign">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Skill #1</Form.Label>
                          <Form.Control
                            type="email"
                            value={this.state.skills[0]}
                            name="skill|0"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Experience #1</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            value={this.state.experiences[0]}
                            name="exp|0"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </Form>{" "}
                      <Form className="formAlign">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Skill #2</Form.Label>
                          <Form.Control
                            type="email"
                            value={this.state.skills[1]}
                            name="skill|1"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Experience #2</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            value={this.state.experiences[1]}
                            name="exp|1"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </Form>{" "}
                      <Form className="formAlign">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Skill #3</Form.Label>
                          <Form.Control
                            type="email"
                            value={this.state.skills[2]}
                            onChange={this.handleChange}
                            name="skill|2"
                          />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Experience #3</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name="exp|2"
                            value={this.state.experiences[2]}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </Form>{" "}
                      <AsyncButton
                        buttonVariants={[
                          "primary",
                          "danger",
                          "danger",
                          "dashed",
                        ]}
                        buttonTexts={[
                          "Update Skills & Experiences",
                          "Updating...",
                          "An error occurred. Try again?",
                          "Updated!",
                        ]}
                        callback={this.updateSE}
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </Container>
        </>
      )
    } else {
      return (
        <Layout full>
          <Loader />
        </Layout>
      )
    }
  }
}
