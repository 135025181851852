import React from "react"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import StudentProfile from "../components/onboarding/StudentProfile"
import dots from "../images/bgs/dots.svg"

 
const ProfileStudent = () => (
  <Layout image={dots}> 
    <SEO title="My Profile" />
    <StudentProfile />
  </Layout>
)

export default ProfileStudent
