import React, { Fragment } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
} from "@react-pdf/renderer"
import logo from "../../images/logo.png"
import signature from "../../images/signature.png"

const borderColor = "#000000"
const backgroundColor = "#FFFFFF"
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 72,
    paddingBottom: 72,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 70,
    height: 70,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  signature: {
    height: 120,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  reportTitle: {
    color: borderColor,
    fontSize: 25,
    textAlign: "center",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: borderColor,
  },
  borderContainer: {
    borderWidth: 1,
    borderColor: borderColor,
  },
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: backgroundColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  taskName: {
    width: "65%",
    borderRightColor: borderColor,
    backgroundColor: backgroundColor,
    borderRightWidth: 1,
  },
  hours: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    backgroundColor: backgroundColor,
  },
  date: {
    width: "25%",
    borderRightColor: borderColor,
    backgroundColor: backgroundColor,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    backgroundColor: backgroundColor,
  },
  rowTaskName: {
    width: "65%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderRightStyle: {
      height: 100,
    },
    paddingLeft: 8,
    backgroundColor: backgroundColor,
  },
  rowHours: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    backgroundColor: backgroundColor,
  },
  rowDate: {
    width: "25%",
    borderRightColor: borderColor,
    textAlign: "center",
    backgroundColor: backgroundColor,
  },
  footerRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
    backgroundColor: backgroundColor,
  },
  footerDescription: {
    width: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    backgroundColor: backgroundColor,
  },
  thankYouTitleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  thankYouReportTitle: {
    fontSize: 12,
    textAlign: "center",
  },
})

export const HoursTable = props => {
  let rows = []
  props.hoursLog.map((item, ind) => {
    rows.push(
      <View style={styles.row}>
        <Text style={styles.rowTaskName}>{item.taskName}</Text>
        <Text style={styles.rowHours}>{String(item.volunteerHours)}</Text>
        <Text style={styles.rowDate}>
          {item.date
            .toDate()
            .toString()
            .substring(4, 16)}
        </Text>
      </View>
    )
    return null
  })
  return (
    <View style={styles.tableContainer}>
      <View style={styles.container}>
        <Text style={styles.taskName}>Project Name</Text>
        <Text style={styles.hours}>Hours</Text>
        <Text style={styles.date}>Date</Text>
      </View>
      <Fragment>{rows}</Fragment>
      <View style={styles.footerRow}>
        <Text style={styles.footerDescription}>
          {`Total Hours: ${props.totalHours}`}
        </Text>
      </View>
    </View>
  )
}

export const CertificateTemplate = props => {
  const { userData, hoursLog, totalHours } = props
  const { firstName, lastName } = userData
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>
            RemoteTA Volunteer Hours Certificate
          </Text>
        </View>
        {hoursLog && hoursLog !== undefined && (
          <HoursTable hoursLog={hoursLog} totalHours={totalHours} />
        )}
        <View style={styles.thankYouTitleContainer}>
          <Text style={styles.thankYouReportTitle}>
            {`This document certifies that as of ${new Date()
              .toString()
              .substring(4, 16).trim()}, ${firstName} ${lastName}
            has completed ${totalHours} volunteer hours. Volunteers may save this as proof of their volunteer hours.`}
          </Text>
        </View>
        <View style={styles.thankYouTitleContainer}>
          <Text style={styles.thankYouReportTitle}>
            Thank you for your service and we hope to see more of your work!
          </Text>
        </View>
        <Image style={styles.signature} src={signature} />
      </Page>
    </Document>
  )
}

export default function VolunteerHoursCertificate(props) {
  let doc = <CertificateTemplate {...props} />
  return (
    <>
      <PDFDownloadLink
        document={doc}
        fileName="remoteta-volunteer-hours-certificate.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Certificate"
        }
      </PDFDownloadLink>
    </>
  )
}
